.main{
  padding-top:2rem;
  padding-bottom: 4rem;
  background-color: #123C69;  
  color: #fff;
  }

.container1 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 100px 300px;
  grid-gap: 10px;
  grid-auto-flow: dense;
}

.gallery-item {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000000;
}

.gallery-item .image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery-item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  cursor: pointer;
  transition: .5s ease-in-out;
}

.gallery-item:hover .image img {
  transform: scale(1.3);
  opacity: 0.4;
  filter: blur(4px);
}

.gallery-item .text1 {
  font-family: 'Raleway';
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 1000;
  transform: translate(-50%, 530%);
  font-size: 22px;
  pointer-events: none;
  z-index: 4;
  transition: .3s ease-in-out;
  width: 100%;
  color: black;
  background-color: #689D4F;
  text-align: center;
}

.gallery-item:hover .text1 {
  opacity: 1;
  animation: move-up .3s linear;
  padding: 1em;
  width: 100%;
  transform: translate(-50%, -120%);
  color: white;
  background-color: transparent;
}

.blog-btn {
  margin-top: 10px;
  width: 120px;
  background-color: #4A6D39;
  color: #fff;
  padding: 6px 6px;
  box-shadow: none;
  outline: none;
  border: none;
  font-size: medium;
}

.gallery-item .text2 {
  font-family: 'Raleway';
  opacity: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  font-weight: 800;
  align-items: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  color: #fff;
  font-size: 20px;
  pointer-events: none;
  z-index: 4;
  transition: .3s ease-in-out;
  width: 100%;
  color: aliceblue;
  text-align: center;
}

.gallery-item:hover .text2 {
  opacity: 1;
  animation: move-up .3s linear;
  padding: 1em;
  width: 100%;
  color: #fff;
  letter-spacing: 0.025rem;
}

.margin-button {
  margin-top: 50px;
}

.w-1 {
  grid-column: span 1;
}

.w-2 {
  grid-column: span 2;
}

.w-3 {
  grid-column: span 3;
}

.w-4 {
  grid-column: span 4;
}

.w-5 {
  grid-column: span 5;
}

.w-6 {
  grid-column: span 6;
}

.h-1 {
  grid-row: span 1;
}

.h-2 {
  grid-row: span 2;
}

.h-3 {
  grid-row: span 3;
}

.h-4 {
  grid-row: span 4;
}

.h-5 {
  grid-row: span 5;
}

.h-6 {
  grid-row: span 6;
}

.blog-heading-section {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  font-family: 'Raleway';
  font-size: 1.5rem;
}

@media screen and (max-width:960px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
  .w-1, .w-2, .w-3, .w-4, .w-5, .w-6 {
    grid-column: span 6;
  }
   .gallery-item .text2{
    opacity: 1;
   }
  .gallery-item .image img {
    transform: scale(1.3);
    opacity: 0.7;
    filter: blur(4px);

  }
}

@keyframes move-down {
  0% {
    top: 10%;
  }
  50% {
    top: 35%;
  }
  100% {
    top: 50%;
  }
}