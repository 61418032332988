.coffee .btn-primary {
  color: #fff;
  background-color: #cc9236;
  border-color: #f6dcb3;
}

.coffee-top-margin {
  margin-top: 100px;
}

.coffee .btn {
  padding: 8px 20px;
  font-size: 18px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

/* Here */

.coffee_banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.coffeeimage {
  max-width: 100%;
  min-height: 100%;
}

.coffeeheader_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.coffee_heading_text_container {
  padding: 80px;
}
.coffeeheading {
  font-weight: 600;
  font-size: 4vw;
}

.coffeesubheading {
  font-size: 3vw;
}

.cof-btn {
  margin-top: 20px;
  width: 16vw;
  padding: 10px;
  background: #5e3828;
  border-radius: 21px;
}

.btn-text {
  text-align: center;
  color: #fff;
  font-size: 2.2vw;
  margin: auto;
}

.coffee_info_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px;
  background-color: #5e3828;
}

.coffee_info {
  color: #fff;
  text-align: center;
}

.coffee_info_sub_container {
  border-right: 1px solid #fff;
}

.coffee-type {
  text-align: center;
  overflow: hidden;
  border-radius: 50%;
}

.coffee-types-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 40px 80px 40px 80px;
}

.cofee-type-img {
  padding: 20px;
  border-radius: 50%;
  width: 70%;
}

.coffee-type-text {
  text-align: center;
  size: 32px;
  font-weight: 400;
}

.coffee-heading {
  text-align: center;
  padding: 20px 0 0 0;
  margin-top: 20px;
}

.coffeecup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.item-1 {
  justify-self: end;
}

.item-2 {
  padding: 30px;
}

.coffee-description {
  max-width: 50%;
}

.coffee-button {
  background: #5e3828;
  border-radius: 21px;
  width: 12rem;
  padding: 5px 30px 5px 30px;
}

hr.style-one {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
}

@media only screen and (max-width: 1275px) {
  .coffee-description {
    max-width: 70%;
  }
}

@media only screen and (max-width: 1050px) {
  /* .coffeeheader {
    top: 130px;
    left: 50px;
  } */

  .coffeesubheading {
    /* position: absolute;
    top: 200px;
    left: 50px; 
    font-size: 400px;*/
  }
  /* 
  .cof-btn {
    left: 55px;
    top: 270px;
    background: #5e3828;
    border-radius: 21px;
  } */
}

@media only screen and (max-width: 980px) {
  /* .coffeeheader {
    font-size: 3rem;
  } */
  /* .coffeesubheading {
    font-size: 2rem;
  }
  .btn-text {
    font-size: 1.5rem;
  } */
}

@media only screen and (max-width: 920px) {
  .coffee-description {
    max-width: 100%;
  }
  .coffee-type-sort-1 {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 772px) {
  .coffeeheader_container {
    grid-template-columns: 1fr;
    grid-column-end: 1;
  }
  .coffee-types-container {
    grid-template-columns: 1fr;
  }
  .coffee_info {
    font-size: 1.1rem;
  }
  .coffeeimage {
    float: right;
    max-width: 100%;
  }
  .coffee_heading_text_container {
    padding: 50px;
  }

  .coffee-description {
    max-width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 2rem;
  }
  .coffeecup {
    grid-template-columns: 1fr;
  }
  .item-1 {
    justify-self: center;
  }
  .item-2 {
    justify-self: center;
    text-align: center;
    padding-top: 0;
  }

  .cofee-type-img {
    width: 90%;
  }

  .coffeeheading {
    font-size: 8vw;
  }

  .coffeesubheading {
    font-size: 6vw;
  }

  .cof-btn {
    width: 32vw;
  }

  .btn-text {
    font-size: 4.4vw;
  }
}
