.bg-mid {
  background-color: #568c3c;
  width: 4%;
}
.img-left {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.con-head {
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 56px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #568c3c;
}
.con-input {
  margin: 1rem auto;
  background: rgba(162, 205, 143, 0.4);
  border-radius: 0.3rem;
  width: 80%;
  padding: 1.2rem;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.06em;
  border: none;
  outline: none;
  color: #000000;
}
.con-textarea{
  height: 10rem;
}
.con-btn {
  margin: 1% 10% 0 auto;
  width: 25%;
  background-color: #568c3c;
  color:white;
  padding:  0.5rem 0.7rem;
  border-radius: 0.3rem;
  border: none;
}
.con-subhead{
  text-align: left;
  font-weight: 600;
  letter-spacing: 0.06em;
  color: #568C3C;
}
::selection {
  background: #ffc8ff;
}

.modal-contact{
border: none;
color: #568C3C;
}
.modal-contact-text{
  font-weight: bolder;
  margin: auto;
}
.close-btn-con{
  color: #568C3C;   
  margin: auto 0;
  font-size: larger;
  right: 0;
  z-index: 123;
  cursor: pointer;
}
.contact-max-height {
  max-height:850px;
}

