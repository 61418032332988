.home {
  font-family: "Raleway";
}

.margin-below {
  margin-bottom: 30px;
}

.text-left {
  text-align: left;
}

.container-custom {
  margin-bottom: 190px;
}

.video-container {
  display: flex;
  direction: row;
  justify-content: center;
}

.video {
  width: 75%;
  height: auto;
  text-align: center;
}

a,
a:hover,
a:active,
a::after {
  opacity: 1;
}

.about_description {
  font-size: 1.3rem;
}

.chikka-logo {
  object-fit: contain;
}

.chikkamagaluru-logo {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.about_description_container {
  margin-top: 20px;
  padding: 0 40px 0 40px;
}

.about-coffee-container {
  padding: 40px;
}

@media screen and (max-width: 768px) {
  .chikkamagaluru-logo {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.coffee-btne {
  margin: 0;
  padding: 0;
}