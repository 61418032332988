/* Containers */
.explore-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.map-filter-container {
  max-width: 50vw;
}
.map-container {
  margin-bottom: 5px;
  max-width: 50vw;
  height: 85vh;
  overflow: hidden;
}
.map-card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px 10px;
  padding: 10px;
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.sidebar {
  width: 50vw;
  max-height: 63vh;
  overflow: auto;
}

/* Card */
.card-image {
  max-height: 200px;
  min-height: 200px;
  object-fit: cover;
}
.card-title {
  text-align: center;
  color: white;
}
.card {
  background-color: #4a6d39;
}

.map-filter {
  background-color: #4a6d39;
  align-content: center;
  justify-content: center;
  padding: 2px 0px;
  text-align: center;
}

.filter-btn {
  margin-left: 10px;
  margin-right: 10px;
  outline: none;
  border: none;
  background-color: transparent;
  color: #ffff;
}

.filter-btn-icon {
  font-size: 1.4rem;
}

.main-map {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 47vw;
}

.overview_title {
  font-family: Raleway;
  margin-bottom: -0.3em;
  margin-top: 0.5em;
  color: #09170e;
  text-align: center;
  font-weight: bold;
}

.title-breadcrumb {
  align-items: center;
  text-align: center;
  color: #09170e;
  font-family: Raleway;
}

#map {
  display: none;
}

@media only screen and (max-width: 750px) {
  .explore-container {
    display: grid;
    grid-template-columns: 1fr;
  }
  .map-filter {
    padding: 50px;
  }

  .map-container {
    display: none;
  }
  .map-filter-container {
    max-width: 100vw;
  }
  .sidebar {
    width: 100vw;
  }
  .map-card-grid {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 1300px) {
  .map-card-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1118px) {
  .map-card-grid {
    grid-template-columns: 1fr;
  }
  .news-card {
    height: 20rem;
  }
}

@media (min-width: 900px) {
  .news-card {
    height: 20rem;
  }
}

.h4 {
  margin-bottom: 200px;
}

.itemlist {
  display: flex;
  overflow: hidden;
  box-sizing: content-box;
  position: absolute;
  flex-direction: row;
}

.modal-header {
  max-height: 70px;
}

.modal-content {
  background-color: #edeef2;
}
.close-btn {
  margin: 1rem;
  font-size: larger;
  right: 0;
  z-index: 123;
  cursor: pointer;
  color: #ffff;
  position: absolute;
}
.dest-detail {
  font-family: "Raleway";
  color: black;
}

.dest-title {
  text-align: center;
  font-family: "Raleway";
  color: #4a6d39;
  font-weight: bold;
  font-size: x-large;
}

/* .modal-dialog {
    min-width: 1000px;
} */
.itemlist::-webkit-scrollbar {
  display: none;
}

.blink_me {
  animation: blinker 1s linear;
  animation-iteration-count: 1;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.news-card {
  border: 0px solid aqua;
  position: relative;
  overflow: hidden;
  border-radius: 0.2rem;
  flex: 1;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.news-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 80%);
  z-index: 0;
}

.news-card__card-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /*     background: rgba(255,0,0,.5); */
}

.news-card__image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  transition: transform 3s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  z-index: -1;
}

.news-card__text-wrapper {
  position: absolute;
  bottom: 0rem;
  padding: 1rem;
  color: white;
  /*     background-color: rgba(0, 0, 0, 0.4); */
  transition: background-color 1.5s ease;
}

.news-card__title {
  font-size: larger;
  transition: color 1s ease;
  margin-bottom: 0.5rem;
}

.news-card__post-date {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  color: #ccc;
}

.news-card__details-wrapper {
  max-height: 0;
  opacity: 0;
  transition: max-height 1.5s ease, opacity 1s ease;
}

@media (min-width: 900px) {
  .news-card:hover .news-card__details-wrapper {
    max-height: 20rem;
    opacity: 1;
  }
  .news-card:hover .news-card__text-wrapper {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .news-card:hover .news-card__title {
    color: yellow;
  }
  .news-card:hover .news-card__image {
    transform: scale(1.2);
    z-index: -1;
  }
}

.news-card__excerpt {
  font-weight: 300;
}

.news-card__read-more {
  background: black;
  color: #a2cd8f;
  display: block;
  padding: 0.3rem 0.6rem;
  border-radius: 0.3rem;
  margin-top: 1rem;
  font-size: 0.8rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-decoration: none;
  width: 7rem;
  margin-left: auto;
  position: relative;
  z-index: 5;
}
.modal-container {
}
.modal-ku {
  width: 100%;
  margin: 0;
}
.news-card__read-more:hover {
  opacity: 0.6;
}
.tooltip-head {
  color: #4a6d39;
  font-family: "Raleway";
  font-weight: bold;
}
.tooltip-des {
  font-family: "Raleway";
}
