.home__hero-section {
  font-family: "Raleway";
  color: #604630;
}
.font-raleway {
  font-family: "Raleway";
}
.card-hover:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  transform: scale(1.05);
  transition: 0.5s all ease 0s;
}

.home__hero-row {
  align-items: center;
}

.home__hero-text-wrapper {
  /* max-width: 540px; */
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  color: #826431;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.heading {
  margin-bottom: 24px;
  font-size: 45px;
  line-height: 1.1;
  font-weight: 600;
  color: #64331f;
}

.darkBg {
  background-color: #e5d9ca;
}

.image-main {
  min-height: 200px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.home__hero-subtitle {
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

.home__hero-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    flex-basis: 100%;
  }
}
.top-margin {
  min-height: 30rem;
  margin-top: 140px;
}

.container-custom {
  margin-bottom: 190px;
}

.bgimage {
  width: 100%;
  height: 500px;
  background: url("https://images.unsplash.com/photo-1438109491414-7198515b166b?q=80&fm=jpg&s=cbdabf7a79c087a0b060670a6d79726c");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.bgimage h5 {
  color: white;
  text-shadow: 2px 2px #333;
}

video {
  width: 75%;
  height: auto;
}
.neumorphic {
  border-radius: 0.5rem;
  background: #ffffff;
}

.neumorphic-img {
  border-radius: 0.5rem 0.5rem 0 0;
}
.circle1 {
  border-radius: 50%;
  max-width: 10rem;
}
.Title {
  text-align: center;
  margin-top: 10rem;
}
.body {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 42px;

  color: #000000;
}
.body ul {
  font-size: 1.5rem;
  list-style-type: disc;
  list-style-position: outside;
  list-style-image: none;
  display: list-item;
}
.body ul li {
  position: relative;
  left: -2rem;
}
@media screen and (max-width: 750px) {
  .body {
    letter-spacing: -0.015em;
    font-size: 1.2rem;
    margin: 0;
  }

  .container-blog-detail {
    margin-top: 40px;
  }
}

.learn-more {
  margin-bottom: 50px;
}

/* dropdown */
.dropdown-toggle:focus {
  background: #4a6d39 !important;
}
.dropdown-toggle,
.dropdown-toggle:hover {
  background: #4a6d39;
}
.show > .dropdown-toggle {
  background: #4a6d39 !important;
}

.dropdown-item:hover {
  background-color: #4a6d39;
  color: #e0d68a;
}

/* filter */
.filter-container {
  color: #4a6d39;
}
.filter-line {
  background-color: #4a6d39;
}

.Search {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  margin-top: 2.6rem;
}
.SearchSpan {
  border: 1px solid #4a6d39;
  background: #4a6d39;
  padding-top: 4px;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  font-size: 20px;
  width: 20%;
}

.SearchClear {
  border-top: 3px solid #4a6d39;
  border-bottom: 3px solid #4a6d39;
  max-width: 1.5rem;
  text-align: center;
  color: #4a6d39;
  font-size: 20px;
  width: 20%;
}
.SearchInput {
  width: 100%;
  border: 3px solid #4a6d39;
  border-right: none;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  outline: none;
}
@media screen and (max-width: 768px) {
  .blogTitle {
    text-align: center;
    margin-left: 0.6rem;
  }
  .padding-xs-none {
    padding-left: 0;
    padding-right: 0;
  }
  .Search {
    margin-top: auto;
    width: 98%;
  }
}

/* .blog-card{
    color:#4a4a4e
}
.head{
    font-weight: 700;
    font-size:larger;
}
.des{
    font-size:large;
} */
a:hover {
  color: inherit;
}
.tags {
  cursor: pointer;
  font-size: medium;
  border-radius: 5rem;
  background-color: #d7dbdf;
}
.tags:hover {
  color: #4a6d39;
}
.tags-container {
  display: inline-block;
  z-index: -123;
  overflow-x: auto;
}
.tags-container::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: #ffffff;
  width: 100%;
  height: 470px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.post-module:hover,
.hover {
  -webkit-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.15);
}
.post-module:hover .thumbnail img,
.hover .thumbnail img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0.6;
}
.post-module .thumbnail {
  background: #000000;
  height: 400px;
  overflow: hidden;
}
.post-module .thumbnail .date {
  position: absolute;
  top: 2rem;
  right: 20px;
  z-index: 1;
  background: #4a6d39;
  width: 55px;
  height: 55px;
  padding: 12.5px 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.post-module .thumbnail .date .day {
  font-size: 18px;
}
.post-module .thumbnail .date .month {
  font-size: 12px;
  text-transform: uppercase;
}
.post-module .thumbnail img {
  display: block;
  width: 120%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.post-module .post-content {
  position: absolute;
  bottom: 0;
  background: #ffffff;
  width: 100%;
  padding: 1.5rem;
  padding-bottom: 0.5rem;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -o-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}
.post-module .post-content .category {
  position: absolute;
  top: -2.55rem;
  left: 0;
  background: #4a6d39;
  padding: 10px 15px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}
.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: #333333;
  font-size: 26px;
  font-weight: 700;
}
.post-module .post-content .sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: #4a6d39;
  font-size: 20px;
  font-weight: 400;
}
.post-module .post-content .description {
  display: none;
  color: #666666;
  font-size: 14px;
  line-height: 1.8em;
}
.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: #999999;
}
.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}
.post-module .post-content .post-meta a {
  color: #999999;
  text-decoration: none;
}
.hover .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}
.container .column {
  width: 50%;
  padding: 0 25px;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
.container .column .demo-title {
  margin: 0 0 15px;
  color: #666666;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.container .info {
  width: 300px;
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}
.container .info span {
  color: #666666;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #4a6d39;
}

.hover {
  transition: 1s ease;
}

.hover:hover {
  z-index: 123;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  transition: 1s ease;
}

.container-blog-detail {
  font-family: Lato, "Lucida Grande", Tahoma, Sans-Serif;
}
