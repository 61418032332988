.attr-btn {
  background-color: #913529;
  /* Green */
  color: white;
}

.attr-btn:hover {
  box-shadow: 8px 8px #5f7853;
  transition: 0.3s;
  background-color: #678757;

  /* 5f7853 446c30 */
}
.category-btn {
  background-color: #5f7853;
  margin-top: 1rem;
}
.category-btn:hover {
  box-shadow: 8px 8px #5f7853;
  transition: 0.3s;
  background-color: #678757;
}
.text-black {
  color: black;
  font-family: "Raleway";
  font-size: 1.5rem;
}
.cat-icon {
  color: #fff;
}
.margin-button {
  margin-bottom: 40px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.attr-col a {
  opacity: 1;
}

.attr-title {
  height: 0;
  opacity: 1;
  transition-delay: 0s;
  transition-duration: 0.4s;
  transform: translateY(100px);
}

.attr-content:hover {
  opacity: 1;
  text-decoration: none;
  /* font-family: monospace; */
}

.attr-content {
  opacity: 0;
  font-size: 1.8rem;
  position: absolute;
  top: 2%;
  left: 0.5%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.637);
  width: 99.25%;
  height: 98%;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  -ms-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  text-align: center;
}
@media screen and (max-width: 1020px) {
  .attr-content {
    opacity: 1;
    font-size: 2rem;
    position: absolute;
    top: 2%;
    left: 2%;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.637);
    width:96%;
    height: 98.2%;
    -webkit-transition: all 400ms ease-out;
    -moz-transition: all 400ms ease-out;
    -o-transition: all 400ms ease-out;
    -ms-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    text-align: center;
  }
}
.home__hero-section {
  color: #604630;
}

.home__hero-row {
  align-items: center;
}

.attr-img {
  min-height: 320px;
  max-height: 320px;
  width: 100%;
  padding: 0px 0.25rem;
  object-fit: cover;
}

.attr-col {
  /* -ms-flex: 50%;
  flex: 25%; */
  padding: 7px 0px 0px 0px;
}

.attr-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 4px;
  align-items: center;
  justify-content: center;
}
li {
  display: block;
  margin: 0;
  padding: 0;
}

.home__hero-text-wrapper {
  /* max-width: 540px; */
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  color: #826431;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.heading {
  margin-bottom: 24px;
  font-size: 45px;
  line-height: 1.1;
  font-weight: 600;
  color: #64331f;
}

.darkBg {
  background-color: #e5d9ca;
}

.home__hero-subtitle {
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

.home__hero-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .attr-img {
    min-height: 300px;
    max-height: 300px;
    width: 100%;
    height: 50%;
  }
  .attr-title {
    opacity: 1;
    padding: 0 0.2rem;
    font-size: 1.3rem;
    transform: translateY(100px);
  }
  .attr-btn {
    transform: scale(0.7);
  }
  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }
  .col {
    flex-basis: 100%;
  }
}

.top-margin {
  margin-top: 140px;
}

.container-custom {
  margin-bottom: 190px;
}

.bgimage {
  width: 100%;
  height: 500px;
  background: url("https://images.unsplash.com/photo-1438109491414-7198515b166b?q=80&fm=jpg&s=cbdabf7a79c087a0b060670a6d79726c");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.bgimage h5 {
  color: rgb(0, 0, 0);
  text-shadow: 2px 2px #333;
}

video {
  width: 75%;
  height: auto;
}
.tourism-banner-container {
  font-family: 'Raleway';
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 10px;
  color: white;
}

@media (min-width: 768px) {
.tourism-banner-container {
  padding: 40px 40px 30px;
}
}

