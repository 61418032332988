.top-margin {
    margin-top: 140px;
}

.holder {
    margin-top: 150px;
    margin-bottom: 50px;
}

.frame {
    top: -100px;
    border-top-color: yellow;
}

.map-box {
    margin: 0;
    padding: 0;
}