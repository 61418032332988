.footer {
  font-family: "Raleway";
  bottom: 0;
}
.footer-green {
  color: #000000;
  background-color: #a2cd8f;
}
.footer-green h3:hover {
  opacity: 0.6;
}

.footer-coffee h3:hover {
  color: white;
}

.footer-coffee {
  color: white;
  background-color: #5e3828;
}

.footer-brown h3:hover {
  color: white;
}

.footer-brown {
  color: #000000;
  background-color: #eee2dc;
}
.footer-brown h3:hover {
  color: #4a6d39;
}
h3 {
  font-stretch: condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  /* line-height: 24px; */
  /* identical to box height, or 104% */
  margin-top: 1rem;
  text-align: center;
  letter-spacing: -0.015em;

  color: #000000;
}
h3 span {
  font-size: 1.6rem;
}

a {
  color: #000000;
  font-size: 1.2rem;
  text-decoration: none;
}

a:hover {
  color: #4a6d39;
  text-decoration: none;
}

@media (max-width: 767px) {
  .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
  .item.text {
    margin-bottom: 0;
  }
}
.item.text {
  margin-bottom: 36px;
}

.item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.item.social {
  text-align: center;
}

@media (max-width: 991px) {
  .item.social {
    text-align: center;
    margin-top: 20px;
  }
}

.item.social > a {
  font-size: 20px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  margin: 0 8px;
  color: #000000;
  opacity: 0.75;
}

.item.social > a:hover {
  opacity: 0.9;
}

.copyright {
  text-align: center;
  font-size: 0.5rem;
  margin-bottom: 0;
}

.coffee-types-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
