/* body {
    background-color: #FFF8F2;
    color: #373539;
    font-family: 'Montserrat', sans-serif;
  } */
  .main-container{
    display: flex;
    flex-direction: row;
    padding: 1rem 0rem;
  }

  .container {
   padding: 1rem 3rem 1rem 3rem;
  }
  
  .faq-drawer {
    margin-bottom: 1.5rem;
  }
  
  .faq-drawer__content-wrapper {
    font-size: 1rem;
    line-height: 1.4em;
    max-height: 0px;
    overflow: hidden;
    transition: 0.55s ease-in-out;
  }
  
  .faq-drawer__title {
    border-top: #000 1px solid;
    cursor: pointer;
    display: block;
    font-size: 1.25em;
    font-weight: 700;
    padding: 1rem 0 0.5rem 0;
    position: relative;
    margin-bottom: 0;
    transition: all 0.25s ease-out;
  }
  p{
      margin-bottom: 0rem;
  }
  .faq-drawer__title::after {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: " ";
    display: inline-block;
    float: right;
    height: 10px;
    left: 2px;
    position: relative;
    right: 20px;
    top: 2px;
    transform: rotate(135deg);
    transition: 0.35s ease-in-out;
    vertical-align: top;
    width: 10px;
  }
  
  /* OPTIONAL HOVER STATE */
  .faq-drawer__title:hover { 
    color: #2e5221  ;
  }
  
  .faq-drawer__trigger:checked
    + .faq-drawer__title
    + .faq-drawer__content-wrapper {
    max-height: 350px;
  }
  
  .faq-drawer__trigger:checked + .faq-drawer__title::after {
    transform: rotate(-45deg);
    transition: 0.25s ease-in-out;
  }
  
  input[type="checkbox"] {
    display: none;
  }
  
  @media only screen and (max-width: 800px) {
    .container {
      padding: 0rem 2rem;
    }
    .main-container{
      flex-direction: column;
    }
  }



  
details {
  width: 75%;
  min-height: 5px;
  max-width: 700px;
  padding: 45px 70px 45px 45px;
  margin: 0 auto;
  position: relative;
  font-size: 22px;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 15px;
  box-sizing: border-box;
  transition: all .3s;
}

details + details {
  margin-top: 20px;
}

details[open] {
  min-height: 50px;
  background-color: #f6f7f8;
  box-shadow: 2px 2px 20px rgba(0,0,0,.2);
}

details p {
  color: #96999d;
  font-weight: 300;
}

summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}

summary:focus {
  outline: none;
  
}

summary:focus::after {
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 5px rebeccapurple;
}

summary::-webkit-details-marker {
  display: none
}

.control-icon {
  fill: rebeccapurple;
  transition: .3s ease;
  pointer-events: none;
}

.control-icon-close {
  display: none;
}

details[open] .control-icon-close {
  display: initial;
  transition: .3s ease;
}

details[open] .control-icon-expand {
  display: none;
}
