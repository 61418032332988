.instagram-container {
  padding: 0 30px 50px 30px;
}

.instaicon {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1;
  color: ivory;
  overflow: hidden;
}

.insta-heading {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-bottom: 20px;
}

.instagram_container_1 {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 5px;
  margin-bottom: 5px;
}

.instagram_container_2 {
  display: grid;
  grid-template-columns: 1fr 3fr 2fr 2fr;
  grid-gap: 5px;
  margin-bottom: 5px;
}

.instagram_container_3 {
  display: grid;
  grid-template-columns: 2fr 2fr 3fr;
  grid-gap: 5px;
  margin-bottom: 50px;
}

.grid__img1 {
  max-height: 260px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.grid__img2 {
  max-height: 250px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.grid__img3 {
  max-height: 270px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.instahover {
  overflow: hidden;
  position: relative;
}

.instahover img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.instahover img:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.instahover:hover .instaicon {
  display: none;
}

@media only screen and (max-width: 950px) {
  .instagram_container_1 {
    grid-template-columns: 2fr 1fr 2fr;
  }
  .instagram_container_3 {
    grid-template-columns: 2fr 2fr 1fr;
  }
  .instagram_container_2 {
    grid-template-columns: 2fr 1fr;
  }
  .instagram-container {
    padding: 10px;
  }
}

@media only screen and (max-width: 950px) {
  .instagram_container_1 {
    grid-template-columns: 1fr 2fr 1fr;
  }
  .instagram_container_3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .instagram_container_2 {
    grid-template-columns: 2fr 1fr;
  }
  .instagram-container {
    padding: 10px;
  }
}
