.head {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    letter-spacing: -0.015em;
}

.description {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    letter-spacing: -0.015em;
}

.volunteer_button {
    align-items: center;
}
.join-btn {
    font-style: normal;
    background-color: #a2cd8f;
    border: 3px solid #a2cd8f !important;
    color: black;
    border-radius: 3rem !important;
    margin: 0.3rem auto;
    font-size: 1.2rem;
}

.join-btn:hover {
    font-style: normal;
    background-color: #a2cd8f;
    color: black;
}

.contact-btn {
    border: 3px solid #a2cd8f !important;
    color: black;
    border-radius: 3rem !important;
    font-size: 1.2rem;

}

.contact-btn:hover {
    color: black;
}