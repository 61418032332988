header {
  font-family: "Raleway";
  position: relative;
  background-color: black;
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
  background-size: cover;
}
.header-text {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 51px;
  line-height: 60px;
  /* identical to box height */

  letter-spacing: -0.015em;

  color: #ffffff;
}

header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

header .container {
  position: relative;
  z-index: 2;
}


header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  z-index: 1;
    background-size: cover;

}

@media (pointer: coarse) and (hover: none) {
  /* header {
      background: url('https://source.unsplash.com/XT5OInaElMw/1600x900') black no-repeat center center scroll;
    }
    header video {
      display: none;
    } */
}
