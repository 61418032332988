
.bg-trans {
    background-color: transparent !important;
}

.bg-dark ,.navbar-active {
    background-color: #4A6D39!important;
}
.coffee-back{
    background-color:#5e3828!important ;
}
.navbar-inner {
    background:transparent;
}
.active-link{
    color: #e0d68a !important;

}
.inactive-link{
    color: white !important;

}
.navbar-nav {
    padding-left: 30px;
    padding-right: 30px;
}
.navbar-brand{
    opacity: 1;
}
.nav-link {
    font-family: 'Raleway';
    margin-left: 5px;
    margin-right: 5px;
    font-size: larger;
    color: white ;
    opacity: 1;
    font-weight: bold;
}
.nav-link:hover{
    color: #e0d68a !important;
    opacity: 1;

}
.btn {
    font-family: 'Raleway';
    margin-left: 5px;
    margin-right: 5px;
}

.navbar {
    padding: 12px;
}

@media (max-width : 991px) {
    .btn {
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .bg-trans {
        background-color: #4A6D39 !important;
    }
}


/* 
@media (min-width: 800px) and (max-width: 850px) {
    .navbar:not(.top-nav-collapse) {
        background: #4A6D39!important;
    }
}
.view,body,html{height:100%}

.navbar{background-color:rgba(0,0,0,.2)}

.page-footer,.top-nav-collapse{background-color:#4A6D39}

@media only screen and (max-width:768px){
    .navbar{background-color:#4A6D39}} */