.about-banner {
  width: 100%;
  object-fit: cover;
  margin-bottom: 40px;
  object-position: top;
}

.about-description {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 117.6%;
}

.registration-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.head {
  font-weight: 700;
  color: #2e5221;
}

.mid-head {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 34px;
  letter-spacing: -0.015em;
}

.list-content {
  font-style: italic;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 23px;
  letter-spacing: -0.015em;
}

.about-section {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}

@media only screen and (max-width: 600px) {
  .about-banner {
    margin-top: 4.4rem;
  }
  .registration-container {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1150px) {
  .about-banner {
    margin-top: 3.4rem;
  }
  .about-section {
    width: 80%;
  }
}

.card-hover:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  transform: scale(1.05);
  transition: 0.5s all ease 0s;
}

.top-margin {
  margin-top: 100px;
}

.comments-title {
  text-align: center;
  margin-bottom: 10px;
  border-bottom: 2px solid black;
}

h2.test2 {
  font-family: "Trebuchet MS", sans-serif;
  font-size: 2em;
  letter-spacing: -2px;
  text-transform: capitalize;
}

p.test2 {
  font-family: sans-serif;
  font-size: 1.2em;
}

hr.custom {
  margin-top: 40px;
  margin-bottom: 40px;
}

hr.custom1 {
  width: 60px;
  height: 5px;
  background: #9d2235;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 2.5px;
}

.text-left {
  text-align: start;
}

.set-center {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.set-up-down-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.about-us strong {
  font-family: "Source Sans Pro", Tahoma, Verdana, Segoe, sans-serif;
  font-weight: bold;
}
